import {
  Box,
  Button,
  CheckBox,
  FormField,
  Heading,
  RadioButtonGroup,
  RangeInput,
  TextArea,
  TextInput,
} from "grommet";
import update from "immutability-helper";
import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";
import CanvasExporter from "./components/CanvasExporter";
import Checkable from "./components/Checkable";
import DropZone from "./components/DropZone";
import CSVParamControl from "./CSVParamControl";
import { ReactComponent as MotorBranding } from "./img/MotorBranding.svg";
import Parameters, { computeParameters } from "./types/Parameters";
import { getFileName, getShaderDoodleCanvas } from "./utils/utils";

const Container = styled(Box).attrs((_) => ({
  background: "transparent",
}))`
  width: 100vw;
  max-width: 100vw;
  height: 28em;
  max-height: 50%;
  overflow-x: auto;
  overflow-y: auto;
  padding: 16px 10px;
  box-sizing: border-box;

  /* This stupid div wrapping seems needed to get a proper scrolling behavior */
  & > div {
    width: calc(100vw - 30px);
    min-width: 1080px;
    height: 100%;
    box-sizing: border-box;

    display: flex;
    flex-flow: row nowrap;
    align-items: top;
  }

  & > div > * {
    min-width: 174px;
    flex: 1 1 0px;
    margin-right: calc((100vw - 6 * 180px) / 6);
  }
  & > div > .contains-range-input {
    margin-right: calc((100vw - 6 * 180px) / 6 + 12px);
  }
  @media all and (max-width: 1074px) {
    & > div > * {
      margin-right: 12px;
    }
    & > div > .contains-range-input {
      margin-right: 12px;
    }
  }
`;

const StyledButton = styled(Button)`
  &,
  &:hover,
  &:focus {
    border-color: transparent;
    box-shadow: none;
  }
  &:hover {
    text-decoration: underline;
  }
  color: black;
  margin-bottom: 16px;
`;

const Logo = styled(MotorBranding)`
  width: 130px;
  margin-left: 12px;
  margin-top: 6px;
  max-width: calc(100% - 16px);
`;

type Props = {
  parameters: Parameters;
  setParameters: React.Dispatch<React.SetStateAction<Parameters>>;
  prepareRecording: () => void;
  startRecording: (duration: number) => void;
  startAnimation: () => void;
  onStopRecording: () => void;
  readyForAnimation: boolean;
  isRecording: boolean;
  csvURL: string;
  selectingEvent: boolean;
  setSelectingEvent: (b: boolean) => void;
};

const ParamControl: React.FC<Props> = ({
  parameters,
  setParameters,
  prepareRecording,
  startAnimation,
  startRecording,
  onStopRecording,
  readyForAnimation,
  isRecording,
  csvURL,
  selectingEvent,
  setSelectingEvent,
}) => {
  const setP = (name: string, value: any, recompute: boolean = false) => {
    setParameters((params) => {
      let p = update(params, { [name]: { $set: value } });
      if (recompute) p = computeParameters(p);
      return p;
    });
  };
  const [editEvent, setEditEvent] = useState(false);

  return (
    <Container>
      <div>
        <div>
          <Logo />
        </div>
        <div>
          <CSVParamControl
            eventId={parameters.eventId}
            setParameters={setParameters}
            csvURL={csvURL}
            open={selectingEvent}
            setOpen={setSelectingEvent}
          />
          <StyledButton
            label={editEvent ? "[X] CLOSE INFO" : "[ ] OPEN INFO"}
            onClick={() => setEditEvent(!editEvent)}
          />
          <br />
          <Heading level="4" margin={{ left: "16px" }}>
            DROP AN IMAGE
          </Heading>
          <DropZone
            onDragOver={(e) => {
              e.stopPropagation();
              e.preventDefault();
              e.dataTransfer.dropEffect = "copy";
            }}
            onDrop={(e) => {
              // From:
              // https://developers.google.com/web/fundamentals/media/capturing-images
              e.preventDefault();
              e.stopPropagation();
              var files = e.dataTransfer.files; // Array of all files

              for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (file.type.match(/image.*/)) {
                  var reader = new FileReader();

                  reader.onload = function (progressEvent) {
                    if (!progressEvent.target) {
                      console.error(
                        "ProgressEvent.target null when reading dropped file"
                      );
                      return;
                    }
                    const src = progressEvent.target.result;
                    if (!src) {
                      console.error("Couldn't read dropped image");
                      return;
                    }
                    if (src instanceof ArrayBuffer) {
                      console.error("Image is an array buffer.");
                      return;
                    }

                    // Clean up the previous image
                    // WARNING: memory leak: If dropping multiple images at the same time,
                    // they won't be all removed.
                    if (parameters.image) parameters.image.remove();
                    // To simply life with shader-doodle, get ready to give it an <img>.
                    const img = document.createElement("img");
                    img.src = src;
                    img.id = "dropped-image" + Date.now();
                    img.style.display = "none";
                    document.body.append(img);

                    const onImageLoad = () => {
                      const ir = parameters.useImageResolution;
                      setParameters(
                        update(parameters, {
                          image: { $set: img },
                          imgWidth: { $set: img.naturalWidth },
                          imgHeight: { $set: img.naturalHeight },
                          width: {
                            $set: ir ? img.naturalWidth : parameters.width,
                          },
                          height: {
                            $set: ir ? img.naturalHeight : parameters.height,
                          },
                        })
                      );
                    };
                    if (img.complete) {
                      onImageLoad();
                    } else {
                      img.addEventListener("load", onImageLoad);
                    }
                  };

                  reader.readAsDataURL(file); // start reading the file data.
                }
              }
            }}
          >
            +
          </DropZone>
        </div>
        {editEvent ? (
          <React.Fragment>
            <div>
              <FormField label="TITLE">
                <TextArea
                  value={parameters.title}
                  onChange={(event) => setP("title", event.target.value, true)}
                />
              </FormField>
            </div>
            <div>
              <FormField label="DESCRIPTION">
                <TextArea
                  style={{ height: "130px" }}
                  value={parameters.serie}
                  onChange={(event) => setP("serie", event.target.value)}
                />
              </FormField>
            </div>
            {/* <div> */}
            {/* <FormField label="OUVERTURE">
                <TextInput
                  value={parameters.timeStart}
                  onChange={(event) => setP("timeStart", event.target.value)}
                />
              </FormField>
              <FormField label="FERMETURE">
                <TextInput
                  value={parameters.timeEnd}
                  onChange={(event) => setP("timeEnd", event.target.value)}
                />
              </FormField> */}
            {/* <FormField label="PROGRAMMATION">
              <TextArea
                value={parameters.program}
                onChange={(event) => setP("program", event.target.value, true)}
              />
            </FormField> */}
            {/* </div> */}
            <div>
              {/* <FormField label="TYPE">
                <RadioButtonGroup
                  name="cacEventType"
                  options={[
                    { label: "SOIREE", value: CACEventType.Party },
                    { label: "CONCERT", value: CACEventType.Concert },
                  ]}
                  value={parameters.eventType}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setP("eventType", parseInt(event.target.value))
                  }
                >
                  {Checkable}
                </RadioButtonGroup>
              </FormField> */}
              <FormField label="PRICE">
                <TextInput
                  value={parameters.venue}
                  onChange={(event) => setP("venue", event.target.value)}
                />
              </FormField>
            </div>
            <div />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div>
              <Heading level="4">RESOLUTION</Heading>
              <RadioButtonGroup
                name="outputResolution"
                options={[
                  { label: "1920 x 1080", value: "1920x1080" },
                  { label: "1080 x 1080", value: "1080x1080" },
                  { label: "1080 x 1920", value: "1080x1920" },
                  { label: "IMAGE", value: "image" },
                ]}
                value={
                  parameters.useImageResolution
                    ? "image"
                    : `${parameters.width}x${parameters.height}`
                }
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const v = event.target.value;
                  const useIR = v === "image";
                  const w = useIR
                    ? parameters.imgWidth
                    : parseInt(v.split("x")[0]);
                  const h = useIR
                    ? parameters.imgHeight
                    : parseInt(v.split("x")[1]);
                  setParameters(
                    update(parameters, {
                      useImageResolution: { $set: useIR },
                      width: { $set: w },
                      height: { $set: h },
                    })
                  );
                }}
              >
                {Checkable}
              </RadioButtonGroup>
            </div>
            <div>
              <Heading level="4">OPTIONS</Heading>
              <Box pad={{ right: "small", bottom: "3px" }}>
                <CheckBox
                  checked={!parameters.drawLayout}
                  label="HIDE LAYOUT"
                  onChange={(event) =>
                    setP("drawLayout", !event.target.checked)
                  }
                />
              </Box>
              {/* <Box pad={{ right: "small", vertical: "6px" }}>
                <CheckBox
                  checked={parameters.bwMode}
                  label="BW"
                  onChange={(event) =>
                    setP("bwMode", Boolean(event.target.checked))
                  }
                />
              </Box> */}
              <Box pad={{ right: "small", vertical: "6px" }}>
                <CheckBox
                  checked={parameters.ditherMode}
                  label="DITHER"
                  onChange={(event) =>
                    setP("ditherMode", Boolean(event.target.checked))
                  }
                />
              </Box>
              <Box pad={{ right: "small", vertical: "6px" }}>
                <CheckBox
                  checked={parameters.animate}
                  label="ANIMATE"
                  onChange={(event) =>
                    setP("animate", Boolean(event.target.checked))
                  }
                />
              </Box>
              {/* <Box pad={{ right: "small", vertical: "6px" }}>
                <CheckBox
                  checked={parameters.animateText}
                  label="ANIMATE TEXT"
                  onChange={(event) =>
                    setP("animateText", Boolean(event.target.checked))
                  }
                />
              </Box> */}
              {/* <Box pad={{ right: "small", vertical: "6px" }}>
                <CheckBox
                  checked={parameters.loopMode}
                  label="LOOP 5 SEC."
                  onChange={(event) =>
                    setP("loopMode", Boolean(event.target.checked))
                  }
                />
              </Box> */}
              <Button
                label="FULLSCREEN"
                margin={{ top: "small" }}
                onClick={() => {
                  getShaderDoodleCanvas()?.requestFullscreen();
                }}
              />
            </div>
            <div className="contains-range-input">
              <Heading level="4">FX</Heading>
              DISPLACEMENT {parameters.axis1}
              <RangeInput
                min={0}
                max={4}
                step={0.01}
                value={parameters.axis1}
                onChange={(event: any) =>
                  setP("axis1", event.target.value || 1)
                }
              />
              SCALE {parameters.axis2}
              <RangeInput
                min={0}
                max={4}
                step={0.01}
                value={parameters.axis2}
                onChange={(event: any) =>
                  setP("axis2", event.target.value || 1)
                }
              />
              COLOUR {parameters.axis3}
              <RangeInput
                min={0.2}
                max={2.5}
                step={0.01}
                value={parameters.axis3}
                onChange={(event: any) =>
                  setP("axis3", event.target.value || 1)
                }
              />
            </div>
            <div>
              <Heading level="4">EXPORT</Heading>
              <CanvasExporter
                prepareRecording={prepareRecording}
                startRecording={startRecording}
                startAnimation={startAnimation}
                onStopRecording={onStopRecording}
                readyForAnimation={readyForAnimation}
                isRecording={isRecording}
                name={getFileName(parameters)}
              />
            </div>
            {/* <div className="contains-range-input"> */}
            {/* <Heading level="4">MAGIC DIGITAL</Heading>
              BRIGHTNESS {parameters.axis4}
              <RangeInput
                min={-1}
                max={1}
                step={0.01}
                value={parameters.axis4}
                onChange={(event: any) =>
                  setP("axis4", event.target.value || 1)
                }
              />
              CONTRAST {parameters.axis5}
              <RangeInput
                min={0.0}
                max={2.0}
                step={0.01}
                value={parameters.axis5}
                onChange={(event: any) =>
                  setP("axis5", event.target.value || 1)
                }
              /> */}
            {/* </div> */}
          </React.Fragment>
        )}
        {/* <TextInput
        placeholder="Width"
        value={parameters.width}
        onChange={(event) =>
          setParameters(
            update(parameters, {
              width: { $set: parseInt(event.target.value) || 1920 },
            })
          )
        }
      />
      <TextInput
        placeholder="Height"
        value={parameters.height}
        onChange={(event) =>
          setParameters(
            update(parameters, {
              height: { $set: parseInt(event.target.value) || 1080 },
            })
          )
        }
      /> */}
      </div>
    </Container>
  );
};

export default ParamControl;
