import "moment/locale/fr";
import moment from "moment";
import sanitize from "sanitize-filename";
import Parameters from "../types/Parameters";

export const getShaderDoodleCanvas = () =>
  getShaderDoodle()?.shadowRoot?.querySelector("canvas") as
    | HTMLCanvasElement
    | null
    | undefined;

export const getShaderDoodle = () =>
  document.querySelector(".generator shader-doodle") as any;

export const resetShaderDoodleTime = () => {
  getShaderDoodle()?.renderer.setUniform("u_time", 0);
};

export const getYYYYMMDD = (date: string) => {
  const elems = date.trim().split(/\D/, 3).reverse();
  if (elems[0] && elems[0].length === 2) elems[0] = "20" + elems[0];
  return elems.join("-");
};

export const getMoment = (str: string) => {
  return moment(str, "DD-MM-YYYY").locale("fr");
};

export const getDayMonthStr = (date: string) => {
  const elems = date.trim().split(/\D/, 2);
  const day = parseInt(elems[0]) || "N/A";
  const month = getMonthName(parseInt(elems[1]));
  return { day, month };
};

export const getMonthName = (monthInt: number) => {
  switch (monthInt) {
    case 1:
      return "janv";
    case 2:
      return "fév";
    case 3:
      return "mars";
    case 4:
      return "avr";
    case 5:
      return "mai";
    case 6:
      return "juin";
    case 7:
      return "juil";
    case 8:
      return "août";
    case 9:
      return "sept";
    case 10:
      return "oct";
    case 11:
      return "nov";
    case 12:
      return "déc";
    default:
      return "N/A";
  }
};

export const getFileName = (parameters: Parameters) => {
  let name = getYYYYMMDD(parameters.date) + "_" + parameters.title + "_";
  if (!parameters.drawLayout)
    name = `canvas_${moment().format("YYYY-MM-DD_HH.mm.ss")}_`;
  name += parameters.width + "x" + parameters.height;
  return sanitize(name);
};

export const getNextPow = (base: number) => {
  for (let i = 1; i < 200; i += 1) {
    if (Math.pow(2, i) > base) return Math.pow(2, i);
  }
  return base;
};

// From:
// https://github.com/jquery/jquery/blob/master/src/css/hiddenVisibleSelectors.js
export const isVisible = function (elem: HTMLElement) {
  return !!(
    elem.offsetWidth ||
    elem.offsetHeight ||
    elem.getClientRects().length
  );
};
