const glsl = (x: TemplateStringsArray) => x.toString();

const ShaderColorUtils = glsl`

// --- From: https://github.com/Jam3/glsl-hsl2rgb/blob/master/index.glsl
float hue2rgb(float f1, float f2, float hue) {
    if (hue < 0.0)
        hue += 1.0;
    else if (hue > 1.0)
        hue -= 1.0;
    float res;
    if ((6.0 * hue) < 1.0)
        res = f1 + (f2 - f1) * 6.0 * hue;
    else if ((2.0 * hue) < 1.0)
        res = f2;
    else if ((3.0 * hue) < 2.0)
        res = f1 + (f2 - f1) * ((2.0 / 3.0) - hue) * 6.0;
    else
        res = f1;
    return res;
}
vec3 hsl2rgb(vec3 hsl) {
    vec3 rgb;
    
    if (hsl.y == 0.0) {
        rgb = vec3(hsl.z); // Luminance
    } else {
        float f2;
        
        if (hsl.z < 0.5)
            f2 = hsl.z * (1.0 + hsl.y);
        else
            f2 = hsl.z + hsl.y - hsl.y * hsl.z;
            
        float f1 = 2.0 * hsl.z - f2;
        
        rgb.r = hue2rgb(f1, f2, hsl.x + (1.0/3.0));
        rgb.g = hue2rgb(f1, f2, hsl.x);
        rgb.b = hue2rgb(f1, f2, hsl.x - (1.0/3.0));
    }   
    return rgb;
}
vec3 hsl2rgb(float h, float s, float l) {
    return hsl2rgb(vec3(h, s, l));
}
// --- End of from: https://github.com/Jam3/glsl-hsl2rgb/blob/master/index.glsl

// --- From: https://stackoverflow.com/questions/15095909/from-rgb-to-hsv-in-opengl-glsl
// All components are in the range [0…1], including hue.
vec3 rgb2hsv(vec3 c)
{
    vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
    vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
    vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

    float d = q.x - min(q.w, q.y);
    float e = 1.0e-10;
    return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
}
vec3 rgb2hsv(float r, float g, float b)
{
    return rgb2hsv(vec3(r, g, b));
}

// All components are in the range [0…1], including hue.
vec3 hsv2rgb(vec3 c)
{
    vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
    return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
}
vec3 hsv2rgb(float h, float s, float v)
{
    return hsv2rgb(vec3(h, s, v));
}
// --- End of from: https://stackoverflow.com/questions/15095909/from-rgb-to-hsv-in-opengl-glsl 


// --- https://shadertoyunofficial.wordpress.com/2019/01/02/programming-tricks-in-shadertoy-glsl/
// Many people rely on full costly RGB2HSV conversion just to get a hue value.
// This can be made a lot simpler using
#define hue(v) ( .6 + .6 * cos( 2.*PI*(v) + vec4(0,-2.*PI/3.,2.*PI/3.,0) ) )  // looks better with a bit of saturation
// code golfed version:
// #define hue(v) ( .6 + .6 * cos( 6.3*(v) + vec4(0,23,21,0) ) )
// --- END

/**
 * Fairly expensive
 */
vec3 hsvShift(vec3 rgb, float hueShift) {
  vec3 hsv = rgb2hsv(rgb);
  hsv.x += hueShift;
  hsv.x = mod(hsv.x, 1.0);
  return hsv2rgb(hsv);
}
vec3 hsvShift(vec3 rgb, float hueShift, float satShift) {
  vec3 hsv = rgb2hsv(rgb);
  hsv.x += hueShift;
  hsv.x = mod(hsv.x, 1.0);
  hsv.y += satShift;
  hsv.y = clamp(hsv.y, 0.0, 1.0);
  return hsv2rgb(hsv);
}
vec3 hsvShift(vec3 rgb, float hueShift, float satShift, float valShift) {
  vec3 hsv = rgb2hsv(rgb);
  hsv.x += hueShift;
  hsv.x = mod(hsv.x, 1.0);
  hsv.y += satShift;
  hsv.y = clamp(hsv.y, 0.0, 1.0);
  hsv.z += valShift;
  hsv.z = clamp(hsv.z, 0.0, 1.0);
  return hsv2rgb(hsv);
}
vec3 hsvProportionalShift(vec3 rgb, float hueShift, float satShift, float valShift) {
  vec3 hsv = rgb2hsv(rgb);
  hsv.x += hueShift;
  hsv.x = mod(hsv.x, 1.0);
  hsv.y += (1.0 - hsv.y) * satShift;
  hsv.y = clamp(hsv.y, 0.0, 1.0);
  hsv.z += (1.0 - hsv.z) * valShift;
  hsv.z = clamp(hsv.z, 0.0, 1.0);
  return hsv2rgb(hsv);
}
vec3 rgbShift(vec3 rgb, float rShift, float gShift, float bShift) {
  rgb.r += rShift;
  rgb.r = clamp(rgb.r, 0.0, 1.0);
  rgb.g += gShift;
  rgb.g = clamp(rgb.g, 0.0, 1.0);
  rgb.b += bShift;
  rgb.b = clamp(rgb.b, 0.0, 1.0);
  return rgb;
}

`;

export default ShaderColorUtils;
