import React from "react";
import styled from "styled-components";
import LayoutConfig, { LayoutItemType } from "../types/LayoutConfig";
import Parameters from "../types/Parameters";
import LayoutWrapper from "./LayoutWrapper";

type StyledProps = {
  width?: number;
  heigth?: number;
};

const Container = styled.div<StyledProps>`
  font-family: "Everett";
  font-weight: normal;
  font-size: 62px;
  line-height: 70px;
  text-align: left;

  position: absolute;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: ${(props) =>
    props.width && props.heigth && props.width < props.heigth
      ? "156px 56px"
      : "48px 56px"};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .li-title {
    color: #151515;
  }
  .li-serie {
    color: #b2b2b2;
    font-weight: 200;
  }
  .li-venue {
    color: #b2b2b2;
    font-weight: 200;
  }
`;

const Description = styled.div<StyledProps>`
  max-width: 87%;
  max-height: 74px;
  /* margin-left: -0.25em;
  margin-right: -0.25em; */
  /* overflow: hidden; */
  /* Prevent the title from pushing InfoContainer to the bottom. */
  /* overflow-y: hidden; */
`;

// Formula found with https://www.desmos.com/calculator
// const titleFontSize = (charCount: number) => {
//   return 290 - 35 * Math.sqrt(0.3 * charCount);
// };

type Props = {
  parameters: Parameters;
  debug?: boolean;
  refToLayout?: any;
};

const PosterLayout03: React.FC<Props> = ({
  parameters,
  debug,
  refToLayout,
}) => {
  const serieDisplay = parameters.serie ? undefined : "none";
  return (
    <LayoutWrapper
      debug={debug}
      canvasWidth={parameters.width}
      canvasHeight={parameters.height}
      refToLayout={refToLayout}
    >
      <Container width={parameters.width} heigth={parameters.height}>
        <span className="li-title">{parameters.title}</span>
        <Description
          className="li-serie"
          data-fit-bounds
          width={parameters.width}
          heigth={parameters.height}
        >
          {parameters.serie}
        </Description>
        <span className="li-venue" style={{ display: serieDisplay }}>
          {parameters.venue}
        </span>
      </Container>
    </LayoutWrapper>
  );
};

export const layout02Config: LayoutConfig = {
  items: [
    { name: "title", type: LayoutItemType.Text },
    { name: "venue", type: LayoutItemType.Text },
    { name: "serie", type: LayoutItemType.Text },
  ],
  decoratorConfigs: [],
};

export default PosterLayout03;
