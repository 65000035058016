import React from "react";
import styled from "styled-components";

const Hider = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
`;

type Props = {
  debug?: boolean;
  canvasWidth: number;
  canvasHeight: number;
  refToLayout?: any;
};

const Container = styled.div<Props>`
  width: ${(props) => props.canvasWidth}px;
  height: ${(props) => props.canvasHeight}px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: ${(props) => (props.debug ? "100" : "-100")};
  transform-origin: top left;
  ${(props) => (props.debug ? "transform: scale(0.5);" : "")}
  text-align: left;
`;

const Cache = styled.div`
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
  top: 0px;
  bottom: 0px;
`;

/**
 * Add functionnality to:
 *  - Show and hide the content
 *  - Scale it when displaying it for debug
 */
const LayoutWrapper: React.FC<Props> = (props) => {
  const Wrapper = props.debug ? React.Fragment : Hider;
  return (
    <Wrapper>
      <Container {...props} ref={props.refToLayout}>
        {props.children}
        {!props.debug && <Cache />}
      </Container>
    </Wrapper>
  );
};

export default LayoutWrapper;
