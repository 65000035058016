import React, { useState, useEffect } from "react";
import "./App.css";
import Parameters, {
  CACEventType,
  computeParameters,
} from "./types/Parameters";
import ParamControl from "./ParamControl";
import Generator from "./Generator";
import { Grommet } from "grommet";
import update from "immutability-helper";
import DecryptPrompt from "./components/DecryptPrompt";
import LoginPage from "./components/LoginPage";
import theme from "./theme";

const encryptedURLMotor2Demo =
  "U2FsdGVkX1/XYpAS/dNlEWxueKYA5lQWGkmUbaRNEH+ORmDRRxPHyj+WwpWqKOKFY7VscOzQ1fDDs/iu/GrbALOp4XAZ2EPGJ13xR/ye3VfDRYnzGRX1AJBd9QOGrh4uc6voQnbJoCVLIvIhPvqYtw3BcZtnny7S55+aLjfkLrVIPsVP1rTW+g/fovFolE30wo8YKYsr4E+GIjq2e2FXR3bCqff49fji1Re2V1k0Ezavt+TJD6UkIo3yPP34IQmF";

const defaultParameters: Parameters = computeParameters({
  eventId: "default-event",
  title: "Motor",
  date: "01.01.2021",
  // title: "Y Otras Chicas",
  // date: "22.10.2020",
  dateTimestamp: 0,
  axis1: 1,
  axis2: 1,
  axis3: 1,
  axis4: 0,
  axis5: 1,
  timeStart: "00:00",
  timeEnd: "24:00",
  serie: "Human Technology.",
  venue: "start editing below",
  program: "",
  eventType: CACEventType.Party,
  organizer: "NV",
  randomSeed: 0.5,
  drawLayout: true,
  bwMode: false,
  ditherMode: true,
  animate: false,
  animateText: false,
  loopMode: false,
  useImageResolution: false,
  width: 1920,
  height: 1080,
  imgWidth: 1920,
  imgHeight: 1080,
  image: document.getElementById("default-shader-image") as HTMLImageElement,
  hue: 0,
  forceRerender: 0,
});

const App: React.FC = () => {
  const [parameters, setParameters] = useState(defaultParameters);
  const [isRecording, setIsRecording] = useState(false);
  const [intervalID, setIntervalID] = useState(-1);
  const [originalTitle, setOriginalTitle] = useState("");
  const [readyForAnimation, setReady] = useState(false);

  // A dirty fix to rerender the whole thing once fonts are loaded.
  // (doing a Paper.js redraw wouldn't be enough as words might have moved.)
  useEffect(() => {
    (document as any).fonts.ready.then(function () {
      // console.log("loaded? " + (document as any).fonts.check("1em Sans Chocs"));
      setParameters((p) =>
        update(p, { forceRerender: { $set: p.forceRerender + 1 } })
      );
    });
  }, []);

  /**
   * The animation flow is something like:
   * - Click 'Export'
   * - prepareRecording() => Update React state for the first frame.
   * - CanvasExporter receives the 'readyForAnimation=true' state and know
   *   that React has updated the layout etc.
   * - CanvasExporter calls startRecording() after having started CCapture
   *   so that JavaScript time is now altered.
   * - startAnimation()
   * - stopAnimation()
   * - stopRecording() -> Called when CCapture is done.
   */

  /**
   * WARNING: setParameters should be called only once per React render cycle
   * or reuse the updated value.
   */
  const prepareParameters = () => {
    if (parameters.title && parameters.animateText) {
      setOriginalTitle(parameters.title);
      setParameters((params) =>
        update(params, { title: { $set: "" }, animate: { $set: true } })
      );
    } else {
      setParameters((params) => update(params, { animate: { $set: true } }));
    }
  };

  const prepareRecording = () => {
    console.log("prepareRecording()");
    prepareParameters();
    setReady(true);
  };

  const startRecording = (exportDuration: number) => {
    console.log("stopAnimation()");
    setIsRecording(true);
    prepareParameters(); // just in case prepareRecording wasn't called?
    if (parameters.animateText) startAnimation();
  };

  const startAnimation = () => {
    const title = parameters.title || originalTitle;
    if (!title) return;
    clearInterval(intervalID);
    // const words = parameters.title.split(/\s+/).filter(Boolean);
    // const iterationCount = parameters.title.length + 1; // + 1 for empty start
    const delay = 1000;
    const ms = 1000; //Math.max((0.5 * exportDuration) / iterationCount, 0.1) * 1000;
    let localIntervalID = -1;
    let currentLastWord = 1;
    console.log("startAnimation()");
    const displayNextWord = () => {
      let i = 0;
      for (let w = 0; w < currentLastWord; w += 1) {
        while (title.charAt(i).match(/\s/) && i < title.length) i += 1;
        while (!title.charAt(i).match(/\s/) && i < title.length) i += 1;
      }
      currentLastWord += 1;
      const str = title.substring(0, i);
      console.log("interval: ", str);
      setParameters((params) =>
        computeParameters(update(params, { title: { $set: str } }))
      );
      if (str.length >= title.length) {
        stopAnimation();
        // doesn't hurt to doubly make sure.
        clearInterval(localIntervalID);
      }
    };
    displayNextWord();
    setTimeout(() => {
      displayNextWord();
      localIntervalID = setInterval(displayNextWord, ms);
      setIntervalID(localIntervalID);
    }, delay);
  };

  const stopAnimation = () => {
    console.log("stopAnimation()");
    clearInterval(intervalID);
    setOriginalTitle("");
    setIntervalID(-1);
  };

  const stopRecording = () => {
    console.log("stopRecording()");
    stopAnimation();
    // TODO? Should call setParameters() ???
    if (originalTitle)
      computeParameters(update(parameters, { title: { $set: originalTitle } }));
    setReady(false);
    setIsRecording(false);
  };

  const [url, setUrl] = useState(null as string | null);
  const [selectingEvent, setSelectingEvent] = useState(false);

  return (
    <Grommet className="App" theme={theme} themeMode="dark">
      {!url ? (
        <LoginPage>
          <DecryptPrompt
            toDecrypt={encryptedURLMotor2Demo}
            onDecrypted={(msg) => {
              setUrl(msg);
            }}
          />
        </LoginPage>
      ) : (
        <React.Fragment>
          <Generator
            parameters={parameters}
            isRecording={isRecording}
            highContrastMode={selectingEvent}
          />
          <ParamControl
            csvURL={url}
            parameters={parameters}
            setParameters={setParameters}
            prepareRecording={prepareRecording}
            startRecording={startRecording}
            startAnimation={startAnimation}
            onStopRecording={stopRecording}
            readyForAnimation={readyForAnimation}
            isRecording={isRecording}
            selectingEvent={selectingEvent}
            setSelectingEvent={setSelectingEvent}
          />
        </React.Fragment>
      )}
    </Grommet>
  );
};

export default App;
