import random from "random";
import seedrandom from "seedrandom";
import { getMoment } from "../utils/utils";

export type EventData = {
  eventId?: string;
  date: string;
  dateTimestamp: number; // Should be computed from date
  title: string;
  eventType: CACEventType;
  organizer: string;
  serie?: string;
  venue: string;
  timeStart: string;
  timeEnd: string;
  with?: string;
  program?: string;
};

/**
 * Should contain all the fields of EventData for easy assignment.
 */
type Parameters = EventData & {
  axis1: number;
  axis2: number;
  axis3: number;
  axis4: number;
  axis5: number;
  randomSeed: number;
  drawLayout: boolean;
  bwMode: boolean;
  ditherMode: boolean;
  animate: boolean;
  loopMode: boolean;
  animateText: boolean;
  useImageResolution: boolean;
  imgWidth: number;
  imgHeight: number;
  width: number;
  height: number;
  image?: HTMLImageElement;
  hue: number;
  forceRerender: number;
};

export const computeEventId = (event: EventData | Parameters) => {
  // Remove all-non digits from the date and all whitespace characters
  return (event.date.replace(/\D+/g, "") + "_" + event.title)
    .replace(/\s/g, "")
    .toLowerCase();
};

export const computeTimestamp = (str: string) => getMoment(str).unix();

export enum CACEventType {
  Party,
  Concert,
}

/**
 * To be called when random parameters should be re-computed
 * based on the new state (for example when the title changed).
 */
export const computeParameters = (parameters: Parameters): Parameters => {
  const eventId = computeEventId(parameters);
  const rng2 = (random as any).clone(seedrandom(eventId));
  return Object.assign({}, parameters, {
    eventId,
    hue: rng2.float(),
    dateTimestamp: computeTimestamp(parameters.date),
  });
};

export default Parameters;
