import React, { useRef, useCallback, useEffect, useState } from "react";
import Parameters from "./types/Parameters";
import PaperMain from "./PaperMain";
import "shader-doodle";
import PosterLayout03, {
  layout02Config,
} from "./poster-layouts/PosterLayout03";
// import shader from "./shaderlib/shader-noises-demo";
import shader from "./shader";
import useDimensions from "react-cool-dimensions";
import throttle from "lodash/throttle";
import Dither from "./img/BayerDither4x4x2.png";
import { ReactComponent as AppIcon } from "./img/happy.svg";
import styled from "styled-components";

const CanvasContainer = styled.div`
  /* Put -100px to show the icon */
  height: calc(100% - 0px);
  width: 100%;
`;

const Header = styled.header`
  /* Remove display:none to show the icon */
  display: none;
  width: 100%;
  height: 100px;

  svg {
    width: 58px;
    height: 58px;
    margin: 22px;
  }
`;

type Props = {
  parameters: Parameters;
  isRecording: boolean;
  highContrastMode?: boolean;
};

const Generator: React.FC<Props> = ({
  parameters,
  isRecording,
  // highContrastMode,
}) => {
  const paperMain = useRef(null as PaperMain | null);
  const nodes = useRef({
    canvas: null as HTMLCanvasElement | null,
    layout: null as HTMLElement | null,
  });

  // Use callback will return that function and recreate it
  // only if nodes or paperMain have changed.
  const startPaperWhenReady = useCallback(() => {
    if (nodes.current.canvas && nodes.current.layout && !paperMain.current) {
      paperMain.current = new PaperMain(
        nodes.current.canvas,
        nodes.current.layout,
        layout02Config
      );
      paperMain.current.update(parameters);
    }
  }, [nodes, paperMain, parameters]);

  const canvasRef = useCallback(
    (canvasNode) => {
      nodes.current.canvas = canvasNode;
      startPaperWhenReady();
    },
    [startPaperWhenReady]
  );
  const layoutRef = useCallback(
    (layoutNode) => {
      nodes.current.layout = layoutNode;
      startPaperWhenReady();
    },
    [startPaperWhenReady]
  );

  const { ref, width = 0, height = 0 } = useDimensions<HTMLDivElement>({
    onResize: throttle(() => {
      // Triggered once per every 500 milliseconds
    }, 500),
  });
  const hRatio = height / parameters.height;
  const wRatio = width / parameters.width;
  const scaleFactor =
    hRatio <= 0 || wRatio <= 0 ? 0.5 : Math.min(hRatio, wRatio, 1);

  // For some reason, Paper.js doesn't update the view width/height
  // automatically
  useEffect(() => {
    if (paperMain.current === null) return;
    paperMain.current.updateSize(parameters.width, parameters.height);
  }, [parameters.width, parameters.height]);
  // Update Paper canvas every time parameters change.
  useEffect(() => {
    if (paperMain.current === null) return;
    paperMain.current.update(parameters);
  }, [parameters]);

  const restartSDKey =
    "" +
    parameters.height +
    parameters.width +
    (parameters.image ? parameters.image.id : "default");

  // Super dirty hack to retard the hue parameter by one frame
  // when recording to avoid flashes due to the paper.js canvas
  // being one frame late when animating the text.
  const [hue, setHue] = useState(parameters.hue);
  useEffect(() => {
    setHue(parameters.hue);
  }, [parameters.hue]);

  // Keep the .generator class name, it's important to select the shader-doodle canvas.
  return (
    <div className="generator">
      <canvas
        id="textCanvas"
        ref={canvasRef}
        width={parameters.width}
        height={parameters.height}
        data-paper-hidpi="off"
      ></canvas>
      <Header>
        <AppIcon />
      </Header>
      <CanvasContainer ref={ref}>
        <shader-doodle
          key={restartSDKey}
          style={{
            height: parameters.height * scaleFactor,
            width: parameters.width * scaleFactor,
          }}
          height={parameters.height}
          width={parameters.width}
          shadertoy
        >
          {/* Use NEAREST for best performance */}
          {/* Use CLAMP_TO_EDGE + NEAREST or LINEAR to avoid having to resize to a power of 2 */}
          <sd-texture
            key="canvas"
            src="#textCanvas"
            force-update
            min-filter="NEAREST"
            mag-filter="NEAREST"
            wrap-t="CLAMP_TO_EDGE"
            wrap-s="CLAMP_TO_EDGE"
            name="u_canvas"
          />
          <sd-texture
            key="dither"
            src={Dither}
            min-filter="NEAREST"
            mag-filter="NEAREST"
            wrap-t="REPEAT"
            wrap-s="REPEAT"
            name="u_dither"
          />
          {parameters.image && (
            <sd-texture
              key="image"
              src={"#" + parameters.image.id}
              name="u_pic"
              wrap-t="CLAMP_TO_EDGE"
              wrap-s="CLAMP_TO_EDGE"
              min-filter="LINEAR"
            />
          )}
          <sd-uniform
            key="bwMode"
            name="bwMode"
            x={parameters.bwMode ? 1 : 0}
            type="float"
          />
          <sd-uniform
            key="hue"
            name="hue"
            x={(isRecording ? hue : parameters.hue) || 0}
            type="float"
          />
          <sd-uniform
            key="ditherMode"
            name="ditherMode"
            x={parameters.ditherMode ? 1 : 0}
            type="float"
          />
          <sd-uniform
            key="animate"
            name="animate"
            x={parameters.animate ? 1 : 0}
            type="float"
          />
          <sd-uniform
            key="loopMode"
            name="loopMode"
            x={parameters.loopMode ? 1 : 0}
            type="float"
          />
          <sd-uniform
            key="axis1"
            name="axis1"
            x={parameters.axis1}
            type="float"
          />
          <sd-uniform
            key="axis2"
            name="axis2"
            x={parameters.axis2}
            type="float"
          />
          <sd-uniform
            key="axis3"
            name="axis3"
            x={parameters.axis3}
            type="float"
          />
          <sd-uniform
            key="axis4"
            name="axis4"
            x={parameters.axis4}
            type="float"
          />
          <sd-uniform
            key="axis5"
            name="axis5"
            x={parameters.axis5}
            type="float"
          />
          <script type="x-shader/x-fragment">{shader}</script>
        </shader-doodle>
      </CanvasContainer>
      <PosterLayout03 parameters={parameters} refToLayout={layoutRef} />
    </div>
  );
};

export default Generator;
