const glsl = (x: TemplateStringsArray) => x.toString();

const ShaderShapes = glsl`

float band(float t,  float start, float end, float blur) {
  return (
      smoothstep(start - blur, start + blur, t) *
      smoothstep(end + blur, end - blur, t)
  );
}

float rectangle(vec2 uv, float xStart, float xEnd, float yStart, float yEnd, vec2 blur) {
  float band1 = band(uv.x, xStart, xEnd, blur.x);
  float band2 = band(uv.y, yStart, yEnd, blur.y);
  return band1 * band2;
}

// --- From http://www.madebyevan.com/shaders/fast-rounded-rectangle-shadows/
// License: CC0 (http://creativecommons.org/publicdomain/zero/1.0/)
/**
 * Faster way to draw box shadows and blurred rectangles.
 * Return the mask for the shadow of a box from lower to upper
 * sigma: size of the shadow, ~ in the given unit
 **/ 
// This approximates the error function, needed for the gaussian integral
vec4 erf(vec4 x) {
  vec4 s = sign(x), a = abs(x);
  x = 1.0 + (0.278393 + (0.230389 + 0.078108 * (a * a)) * a) * a;
  x *= x;
  return s - s / (x * x);
}
// Return the mask for the shadow of a box from lower to upper
float boxShadow(vec2 lower, vec2 upper, vec2 point, float sigma) {
  // Nathan Vogel: Optimize by not calculating beyond the blur distance. 
  // 2.5 seems about correct, 2.3 might cut a tiny bit.
  if (point.y < lower.y - sigma * 2.3) return 0.0;
  if (point.y > upper.y + sigma * 2.3) return 0.0;
  if (point.x < lower.x - sigma * 2.3) return 0.0;
  if (point.x > upper.x + sigma * 2.3) return 0.0;
  vec4 query = vec4(point - lower, point - upper);
  vec4 integral = 0.5 + 0.5 * erf(query * (sqrt(0.5) / sigma));
  return (integral.z - integral.x) * (integral.w - integral.y);
}
// --- End of: From madebyevan.com --------------------

`;

export default ShaderShapes;
