import { Box, ThemeContext, ThemeType } from "grommet";
import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../img/MotorBrandingWhite.svg";

const themeOverrides: ThemeType = {
  global: {
    colors: {
      background: "black",
      placeholder: "#bbbbbb",
      border: "#aaaaaa",
    },
    font: {
      family: "'Comic Sans', Montserrat, Roboto, sans-serif",
      size: "8px",
    },
    focus: {
      border: {
        color: "#ffffff",
      },
    },
  },
  button: {
    primary: {
      color: "white",
    },
    border: {
      radius: "0px",
      color: "white",
    },
    color: "white",
    option: { color: "white" },
  },
  formField: {
    border: {
      side: "all",
      color: "white",
    },
  },
  textInput: {
    extend: ``, // override my padding override
  },
};

const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;

  #login-page-logo {
    width: 130px;
    height: 25px;
    margin: 3em;
  }
`;

const LoginPage: React.FC = ({ children }) => {
  return (
    <ThemeContext.Extend value={themeOverrides}>
      <Page>
        <Logo id="login-page-logo" />
        <Box width="medium">{children}</Box>
      </Page>
    </ThemeContext.Extend>
  );
};

export default LoginPage;
