/**
 * Dummy function for tagged use of glsl backtick strings
 * to get VSCode to syntax-highlight them.
 */
const glsl = (x: TemplateStringsArray, ...objects: any) => {
  let str = "";
  for (let i = 0; i < x.length; i++) {
    str += x[i];
    if (objects && i < objects.length) {
      str += objects[i].toString();
    }
  }
  return str;
};

export default glsl;
