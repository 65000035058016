import { ThemeType } from "grommet";
import { AddCircle, Close, SubtractCircle } from "grommet-icons";

const theme: ThemeType = {
  global: {
    font: {
      family: "TGR Mono",
      size: "10px",
      height: "1.4",
    },
    colors: {
      focus: "#01010155",
      text: {
        dark: "white",
        light: "#010101",
      },
      white: "#FFFFFF",
      black: "#000000",
      brand: "#010101",
    },
    focus: {
      shadow: {
        size: "1px",
      },
    },
  },
  accordion: {
    heading: {
      level: "3",
    },
    hover: {
      heading: {
        color: "brand",
      },
    },
    icons: {
      collapse: SubtractCircle,
      expand: AddCircle,
      color: "brand",
    },
    border: undefined,
  },
  rangeInput: {
    thumb: {
      color: "#010101",
      extend: `
        top: 10px; /* needed Chrome fix */
        height: 10px;
        width: 10px;
        border: 0px;
    `,
    } as any,
    track: {
      color: "#E0E0E0",
      lower: {
        color: "#777777",
      },
      extend: `
        height: 10px;
    `,
    } as any,

    extend: `
      margin: 2px 0px;
    `,
  },
  text: {
    medium: {
      size: "10px",
      height: "1.4",
    },
  },
  heading: {
    level: {
      "4": {
        medium: {
          size: "10px",
          height: "1.4",
        },
        font: { weight: "400" },
      },
    },
    extend: `
      margin-top: 0px;
      margin-bottom: 0.8em;
      color: black;
    `,
  },
  button: {
    border: {
      width: "1px",
    },
    padding: {
      horizontal: "16px",
      vertical: "1px",
    },
    extend: `
    `,
  },
  checkBox: {
    check: {
      radius: "0px",
      thickness: "1px",
      extend: ({ theme, checked }) => `
        // ${checked && `background-color: #010101;`}
        path { fill: red; }
        `,
    },
    border: {
      width: "1px",
      color: "#010101",
    },
    hover: { border: { color: "#cc0000" } },
    icon: {
      size: "10px",
    },
    // icons: {checked: () => '[x]'},
    icons: { checked: Close },
    size: "10px",
  },
  radioButton: {
    size: "10px",
    icon: { size: "10px" },
    border: {
      width: "1px",
      color: "#010101",
    },
    hover: { border: { color: "#cc0000" } },
    check: {
      radius: "0px",
    },
  },
  formField: {
    border: {
      side: "bottom",
      color: "transparent",
    },
    margin: "xsmall",
    error: {
      size: "xsmall",
    } as any,
    help: {
      size: "xsmall",
    } as any,
    info: {
      size: "xsmall",
    } as any,
    label: {
      size: "medium",
      color: "black",
    },
    round: "0px",
  },
  textInput: {
    extend: `
      padding-top: 6px;
      padding-bottom: 6px;
    `,
  },
};

export default theme;
