import React, { useState } from "react";
import { TextInput, FormField, Form, Button } from "grommet";
import { FormNextLink } from "grommet-icons";
import AES from "crypto-js/aes";
import encUtf8 from "crypto-js/enc-utf8";
import styled from "styled-components";

// Developers: Use the following to encrypt information so
// it can be embedded in the code and let the user password decrypt it.

// const message = "THE_CONTENT_TO_ENCRYPT"
// var encrypted = AES.encrypt(message, "THE_PASSWORD").toString();
// console.log("encrypted", encrypted);
// var decryptedBytes = AES.decrypt(encrypted, "THE_PASSWORD");
// var originalText = decryptedBytes.toString(encUtf8);
// console.log("decrypted", originalText);

// CryptoJS's AES automatically uses a salt when provided
// a string, so the following is not needed:
// var salt = WordArray.random(128 / 8);
// var key256Bits = PBKDF2("thepassphrase", salt, {
//   keySize: 256 / 32,
// });
// console.log("key:", key256Bits, key256Bits.toString());

type Props = {
  toDecrypt: string;
  onDecrypted: (decrypted: string) => void;
};

const StyledForm = styled(Form)`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;

  *:first-child {
    flex-grow: 1;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #ffffff11 inset !important;
    transition: background-color 1000s ease-in-out 0s;
    color: white !important;
    -webkit-text-fill-color: #ffffff;
  }
`;

const StyledButton = styled(Button)`
  padding: 6px;
`;

const DecryptPrompt: React.FC<Props> = ({ toDecrypt, onDecrypted }) => {
  const [error, setError] = useState("");

  return (
    <StyledForm
      onSubmit={(event: any) => {
        const password = event.value.password2020;
        let originalText = "";
        try {
          let decryptedBytes = AES.decrypt(toDecrypt, password);
          originalText = decryptedBytes.toString(encUtf8);
        } catch (e) {
          console.error(e);
          setError("Invalid password");
        }
        if (originalText) onDecrypted(originalText);
        else setError("Invalid password");
      }}
    >
      <FormField name="password2020" error={error}>
        <TextInput
          type="password"
          name="password2020"
          placeholder="Enter Password..."
          onChange={() => setError("")}
        />
      </FormField>
      <StyledButton type="submit" icon={<FormNextLink color="white" />} />
    </StyledForm>
  );
};

export default DecryptPrompt;
