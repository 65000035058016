import Decorator from "../decorators/Decorator";

export enum LayoutItemType {
  Text,
  DummyRect,
  SVG,
}

type LayoutConfig = {
  items: { name: string; type: LayoutItemType }[];
  decoratorConfigs: DecoratorConfig[];
};

type DecoratorConfig = {
  decorator: typeof Decorator;
  /**
   * Arguments to pass to the constructor
   */
  config?: { [key: string]: any };
  /**
   * Used if present
   */
  target?: string;
  /**
   * Used only if target is falsy
   */
  targets?: string[];
};

export default LayoutConfig;
